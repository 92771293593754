
import Vue from "vue";
export default Vue.extend({
  data() {
      let model = this.input.value
    return {
      active: false,
      model,
    };
  },
  props: {
    input: Object,
  },
  methods: {
    change(val: any) {
        this.active = false
        this.model = val
        this.input.val = val
      this.$emit("change", val);
    },
  },
});
